.sidebar ul li {
  padding: 5px 1vw; font-size: 110%;
  border-bottom: 1px solid #cacaca;
}
.sidebar ul li a {
  color: var(--primary-color); font-weight: 400;
  display: inline-block; padding: 2px 7px; /* width: 100%; */
}
.sidebar ul li:hover { cursor: pointer; }
.sidebar ul li:hover a { font-weight: 500; cursor: pointer; }
.sidebar ul li a:focus {
  font-weight: 500; background-color: #f6f6f6;
  text-decoration: underline; box-shadow: 0 0 3px #666;
  outline: 1px solid rgba(102,102,102,0.5);
}
