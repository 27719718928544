.topbar-top {
  background-color: rgba(0,0,0,0.7); width: 100%; text-align: right;
}
/* .topbar-topmenu { list-style: none; padding-right: 23px; display: inline-block; }
.topbar-topitem { display: inline-block; margin: 1px 3.2px; text-transform: capitalize; }
.topbar-topitem a {
  color: #fff; text-decoration: none; padding: 5px 8px; margin-bottom: 1px;
  font-size: 12px; font-weight: 500; position: relative;  display: inline-block;
}
.topbar-topitem a img {
  max-width: 14px; max-height: 14px; position: absolute; top: 8px; right: -9px;
} */

/* .topbar-logo {
  display: flex; align-items: center; text-transform: capitalize; height: 78px;
}
.topbar-logo div { flex: 1; margin: 0 10px; }
.topbar-logo img { max-width: 200px; width: 100%; max-height: 100%; min-height: 40px; }
.topbar-logo-srch { text-align: right; color: rgba(0,0,0,0.7); }
.topbar-logo-srch span { margin-right: 8.5px; cursor: pointer; font-weight: 600; }
.topbar-logo-srch span i { margin-left: 10px; }
.topbar-logo-srch span:hover { color: #ed1b24; } */

/* .topbar-srch {
  width: 100%; height: 0; overflow: hidden; padding: 10px 0; /* 62px * /
  background-color: #fff; position: absolute; z-index: 9;
  /* transition: height 1s .2s ease, z-index 1.5s, display 0s 1s; * /
  border-top: 1px solid #d3d3d3; border-bottom: 1px solid #d3d3d3;
  transition: height 1s .2s ease, padding 1s .2s, z-index 1.5s;
}
.topbar-srch-frm {
  display: flex; align-items: center; flex-wrap: wrap;
}
.topbar-srch1, .topbar-srch2 { height: 100%; padding: 0 15px; }
.topbar-srch1 { flex: 10; }
.topbar-srch2 { flex: 2.8; padding-right: 15px; }
.topbar-srch1-inp, .topbar-srch2-btn {
  font-family: 'Exo 2', sans-serif; font-size: 15px; line-height: 1.5; height: 40px;
}
.topbar-srch1-inp { display: block; border: 0; width: 100%; }
/* .topbar-srch-inp:focus { border: 1px solid #efefef; } * /
/* .topbar-srch-inp:active { border: none; } * /
.topbar-srch2-btn {
  background-color: #ed1b24; width: 65%; margin-right: 15px;
  border: none; border-radius: 5px; color: #fff; cursor: pointer;
}
.topbar-srch2-cls {
  text-align: center; cursor: pointer;
  font-weight: 600; text-decoration: underline;
} */

.topbar-nav {
  position: sticky; top: 0; z-index: 10; /* position: relative; /* height: 51px; */
  display: flex; background-color: #fff; /* align-items: center; */
  max-height: 76px;
}
.topbar-nav1 {
  flex: 59; /*background-color: rgb(226, 19, 110); /*#ed1b24;*/
  transition: flex 0.1s; position: relative;
}
.topbar-nav1 a { text-decoration: none; }
.topbar-nav1>div { display: inline-block; vertical-align: middle; }
.topbar-nav1>div.logo { position: relative; }
.topbar-nav1>div.logo img { height: 56.4px; width: 268px; margin-top: 2px; }
.topbar-nav1>ul {
  display: inline-block; list-style: none; vertical-align: middle;
  height: 60.4px; padding-top: 14px; /*15px*/
}
/* .topbar-nav1>ul img { height: 58.4px; margin-top: 1px; } */
.topbar-nav1>ul>li {
  display: inline-block; margin-left: 10px;
  border-bottom: 4px solid transparent; vertical-align: middle;
}
.topbar-nav1>ul>li.imp.menu, .topbar-nav1>ul>li.imp.logo /*,
.topbar-nav1>ul>li:first-of-type */ { border-bottom: none; margin-right: 1vw; }
.topbar-nav1>ul>li>a, .topbar-nav1>div.right .lang a {
  color: var(--primary-color); font-weight: 400; /*#fff*/
  display: inline-block;
}
.topbar-nav1>ul>li>a {
  padding: 11px 13px 3px 13px; /* 14px 8px 10.5px 18px; */
  font-size: 120%;
}
/* .topbar-nav1>ul>li:first-of-type>a { padding-top: 0; padding-bottom: 0; } */
.topbar-nav1>ul>li>span {
  color: var(--primary-color); text-align: center; /* display: none; */ /*#fff*/
  height: 45px; line-height: 45px; display: inline-block;
  padding-right: 8px;
}
.topbar-nav1>ul>li>span i { font-size: 80%; }
/* .topbar-nav1>ul>li>a i { font-size: 93%; } */
/* .topbar-nav1>ul>li>a:hover i { font-size: 100%; } */
.topbar-nav1>ul>li>span:hover i { font-size: 100%; }
/* .topbar-nav1>ul>li:hover { border-bottom: 4px solid #fff; } */
/* .topbar-nav1>ul>li:first-of-type:hover { border-bottom: none; } */
.topbar-nav1>ul>li a:hover { font-weight: bolder; }
.topbar-nav1>ul>li a:focus {
  text-decoration: underline; box-shadow: 0 0 3px #666;
  outline: 1px solid rgba(102,102,102,0.5);
  background-color: #f6f6f6; font-weight: 600;
}
.topbar-nav1>ul>li .dropdown-box {
  position: absolute; top: 60.4px; left: 0; width: 99.1072vw;
  background-color: #f6f6f6; padding: 10px 0;
}
.topbar-nav1>ul>li .dropdown-box .container {
  width: 1400px; max-width: 100vw; padding: 0 10px;
  margin-left: auto; margin-right: auto; display: flex;
}
.topbar-nav1>ul>li .dropdown-box .container nav {
  flex: 1; padding: 10px;
}
.topbar-nav1>ul>li .dropdown-box .container li {
  padding: 10px 0; color: var(--primary-color);
  border-bottom: 1px solid #cacaca;
}
.topbar-nav1>ul>li .dropdown-box .container li a {
  color: var(--primary-color); font-size: 118%; font-weight: 400;
}
.topbar-nav1>ul>li .dropdown-box .container li a:hover { text-decoration: underline; }
.topbar-nav1>ul>li .dropdown-box .container div {
  flex: 1; padding: 10px;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card {
  display: block; position: relative;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container {
  width: 100%; height: 370px; overflow: hidden; padding: 0;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container img {
  display: block; width: 100%; object-fit: cover;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container img.top {
  height: 60%; transition: transform .4s, height .4s;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container:hover img.top {
  transform: scale(1.05,1.05); height: 58.5%;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container:hover img.below {
  height: 42.5%;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container .gradient-blurred {
  position: absolute; left: 0; bottom: 0; width: 100%; height: 40%; padding: 0; z-index: 1;
  background-image: linear-gradient(to bottom, rgba(51,51,51,0.3), rgba(51,51,51,0.6));
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .img-container img.below {
  height: 40%; transition: height .4s;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .card-body {
  position: absolute; left: 0; bottom: 0; width: 100%; padding: 15px; z-index: 2;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .card-body .card-info {
  background-color: #fff; padding: 26px 15px 15px; min-height: 182px;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .card-body .card-info h4 {
  margin: 16px 0 12px; color: #333; text-transform: none; line-height: 1.3;
  font-size: 135%; font-weight: 500; font-stretch: ultra-expanded; letter-spacing: 0.65px;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card:hover .card-info h4 {
  text-decoration: underline;
}
.topbar-nav1>ul>li .dropdown-box .container div a.card .card-body .card-info p {
  color: #333; line-height: 1.3; font-weight: 400; font-size: 108%;
}

/* .topbar-nav1>ul>li.btn, .topbar-nav1>ul>li.lang {
  border-bottom: transparent; float: right;
}
.topbar-nav1>ul>li.btn { margin-top: 16px; padding-top: 7px; } */
/* .topbar-nav1>ul>li.lang { margin-top: 9px; color: #fff; vertical-align: middle; }
.topbar-nav1>ul>li.lang a:last-of-type { padding-left: 7px; vertical-align: middle; } */
/* .topbar-nav1>ul>li.btn div {
  border-radius: 50%; background-color: #eee; vertical-align: middle;
  height: 25.5px; width: 25.5px; display: inline-block; margin-top: -2.5px;
  background-color: transparent; border: 1px solid #fff;
}
.topbar-nav1>ul>li.btn div:last-of-type {
  margin-left: -13px; z-index: 1; position: relative;
}
.topbar-nav1>ul>li.btn button {
  margin-left: -13px; border: 0; z-index: 10; position: relative;
  background-color: rgb(226, 19, 110);
  color: #fff; padding-top: 1px;
  border-top: 1px solid #fff; border-bottom: 1px solid #fff;
}
.topbar-nav1>ul>li.btn:hover div, .topbar-nav1>ul>li.btn:hover button {
  background-color: #fff; color: rgb(226, 19, 110);
} */
/* .topbar-nav1>ul>li.btn a {
  border: 2px solid #fff; overflow: hidden;
  border-radius: 42%; line-height: 0.6;
  display: inline-block; padding-right: 14px;
}
.topbar-nav1>ul>li.btn a:hover {
  background-color: #fff; color: rgb(226, 19, 110);
} */

.topbar-nav1>div.right { float: right; }
.topbar-nav1>div.right>div { display: inline-block; }
.topbar-nav1>div.right>div.btn div {
  border-radius: 50%; display: inline-block; vertical-align: middle;
  height: 25.5px; width: 25.5px; margin-top: -2.5px;
  background-color: transparent; border: 1px solid var(--primary-color); /*#fff;*/
}
.topbar-nav1>div.right>div.btn div:last-of-type {
  margin-left: -13px; /* z-index: 1; position: relative; */
}
.topbar-nav1>div.right>div.btn button {
  margin-left: -13px; border: 0; z-index: 10; position: relative;
  /*background-color: rgb(226, 19, 110); /*#ed1b24;*/
  color: var(--primary-color); padding-top: 1.8px; /*#fff;*/
  border-top: 1px solid var(--primary-color); border-bottom: 1px solid var(--primary-color);
}
.topbar-nav1>div.right>div.btn:hover div, .topbar-nav1>div.right>div.btn:hover button {
  background-color: #fff; color: rgb(226, 19, 110); /*#ed1b24;*/
}
.topbar-nav1>div.right .lang { margin-top: 9px; color: var(--primary-color); /*#fff;*/ }
.topbar-nav1>div.right .lang a { padding: 14px 8px 10.5px 18px; }
.topbar-nav1>div.right .lang a:last-of-type { padding-left: 7px; vertical-align: middle; }

.topbar-nav1 .imp.menu { float: right; width: 13.13%; }
.topbar-nav1 .imp.menu>a {
  padding: 0; height: 51px; width: 100%; background-color: #000;
}
.topbar-nav1 .imp.menu img {
  height: 70%; width: 90%; margin-left: 6%; margin-top: 7px;
  position: relative;
}
.topbar-nav1 .imp.menu div.screen {
  background-color: #000; position: absolute; top: 0;
  width: 100%; height: 51px; opacity: 0.5;
}
.topbar-nav1-dropdown { display: none; }
.topbar-nav1-dropdown li a { display: block; }
.topbar-nav1-dropdown li { display: block; }
.topbar-nav1-dropdown li a:hover {
  background-color: rgb(226, 19, 110); /*#ed1b24;*/
  color: #fff;
}
.topbar-nav1-mobile {
  color: #fff; padding: 12px 10px; cursor: pointer; display: none;
}
.topbar-nav1-mobile i { padding: 0 5px; font-size: 80%; }
.topbar-nav1-mobile.active i, .topbar-nav1>ul>li.showDropdown i {
  transform: rotate(180deg);
}
.topbar-nav1-mobile:hover { font-weight: bolder; }
.topbar-nav1-mobile:hover i { font-size: 100%; }

.topbar-nav1 .imp.logo {
  background-repeat: no-repeat; /* transition on background-image doesn't work */
  transition: background-image 2s, background-size 0.1s;
}
.topbar-nav1 .imp.logo.stk {
  background-image: url(/public/logo.png), linear-gradient(#fff, #fff);
  background-size: 95% 70%, cover; width: 15.79%; height: 75px;
  position: absolute; top: 0; right: 14.45%;
}

.topbar-nav2 {
  flex: 6.5; background-position: center; height: 100%; flex-basis: 0;
  z-index: 10; background-repeat: no-repeat;
  transition: flex 0.1s, background-image 0.1s, background-size 0.1s;
}
.topbar-nav2.bckimg {
  background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))
  , url(/public/logo.png), linear-gradient(#fff, #fff);
  background-size: cover, 95% 70%, cover;
}
.topbar-nav2.stkimg {
  background-image: url(/public/logo.png), linear-gradient(#fff, #fff);
  background-size: 95% 70%, cover;
}
.topbar-nav3 {
  flex: 8.5; background-position: center; height: 51px; /* 100% */
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))
  , url(/public/logo1.png), linear-gradient(#000, #000); z-index: 10;
  background-size: cover, 90% 70%, cover; background-repeat: no-repeat;
  /* box-shadow: inset 0 0 0 9px #000; */
}
.topbar-nav4 {
  flex: 1; z-index: 10;
  /*background-color: rgb(226, 19, 110); /*#ed1b24;*/
  height: 51px; /* 100% */
}
.topbar-top .highlight, .topbar-nav .highlight,
.topbar-nav .highlight>a, .topbar-nav .highlight i {
  color: #ff0 !important; font-weight: bold !important;
}
.topbar-nav .highlight>ul a { font-weight: 400; }
.topbar-nav .highlight {
  animation: 2.6s linear infinite topbar-blinker;
}
.topbar-nav .highlight:hover { animation: none; }
@keyframes topbar-blinker {
  0%  { opacity: 1.0 } 25% { opacity: 1.0 }  60% { opacity: 0.5 }
  65% { opacity: 0.2 } 75% { opacity: 0.5 } 100% { opacity: 1.0 }
}

.topbar-nav1 .imp.logo {
  background-image: url(/public/logo.png), linear-gradient(#fff, #fff);
  background-size: 100px 70%, cover; height: 51px;
}
.topbar-nav1 .imp.logo::after {
  display: block; content: " ";
  background-image: linear-gradient(rgb(255,255,255), rgb(255,255,255));
  background-size: cover; opacity: 0.7; height: 46px; transition: opacity .5s;
}
.topbar-nav1 .imp.logo:hover::after, .topbar-nav1 .imp.logo.stk::after { opacity: 0; }

@media only screen and (min-width:991px) {
  .topbar-nav1-dropdown {
    position: absolute; z-index: 10; top:51px;
    background-color: #fff; width: 241px;
  }
  .imp.menu .topbar-nav1-dropdown { left: -100px; }
  .topbar-nav1 .imp.menu:hover { border-bottom: none; }
  .topbar-nav1 .imp.menu:hover div.screen { opacity: 0.1; }
  .topbar-nav1>ul>li:hover .topbar-nav1-dropdown { display: block; }
  .topbar-nav1-dropdown li a {
    padding: 10px 15px; border-bottom: 1px solid #f5f5f5;
  }
  .topbar-nav1 .imp.logo {
    float: right; width: 9.94%; background-position: center;
    /* background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))
    , url(/public/logo.png), linear-gradient(#fff, #fff);
    background-size: cover, 95% 70%, cover; height: 51px; */
  }
}
@media only screen and (min-width:1191px) {
  .topbar-nav1>ul { margin-left: 3vw; }
}
@media only screen and (min-width:1241px) {
  .topbar-nav1>ul { margin-left: 5vw; }
}
@media only screen and (min-width:1391px) {
  .topbar-nav1>ul { margin-left: 7vw; }
}
@media only screen and (min-width:1481px) {
  .topbar-nav1>ul { margin-left: 9vw; }
}

@media only screen and (max-width:991px) {
  .topbar-top { text-align: left; padding: 4.5px 0; }
  .topbar-topmenu { padding: 0 15px; }
  .topbar-sm-hide { display: none; }
  .topbar-logo { height: 68px; }
  /* .topbar-nav { position: relative; } */
  .topbar-srch { padding: 0; }
  .topbar-srch1 { flex-basis: 100%; }
  .topbar-srch1-inp { height: 32px; }
  .topbar-srch2-btn { height: 38px; }
  .topbar-nav1>ul, .topbar-nav2, .topbar-nav3 { display: none; }
  .topbar-nav1>ul {
    position: absolute; top: 46px; background-color: rgb(226, 19, 110); /*#ed1b24;*/
    border-top: 4px solid #fff; width: 100%;
  }
  .topbar-nav1>ul>li { display: block; }
  .topbar-nav1>ul>li>a { width: 90%; }
  .topbar-nav1>ul>li>span {
    display: inline-block; position: absolute; right: 0;
    width: 10%; padding-left: 4%; cursor: pointer;
  }
  .topbar-nav .highlight>ul a { color: #ff0 !important; }
  .topbar-nav1-mobile { display: inline-block; }
  .topbar-nav1 li.showDropdown .topbar-nav1-dropdown { display: block; }
  .topbar-nav1 li.showDropdown ul { background-color: rgb(226, 19, 110); /*#de1c24;*/ }
  .topbar-nav1 li.showDropdown ul a { border-left: 4px solid transparent; }
  .topbar-nav1 li.showDropdown ul a:hover { border-left: 4px solid #fff; }
  .topbar-nav1-dropdown li a { margin: 10px 0; padding: 0 25px; color: #fff; }
  .topbar-nav1 .imp.menu, .topbar-nav1 .imp.logo { float: none; width: 100%; }
  .topbar-nav1 .imp.menu { border-bottom: none; }
  .topbar-nav1 .imp.menu img { max-width: 128px; margin-left: 13px; }
  /* .topbar-nav1 .imp.logo {
    background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))
    , url(/public/logo.png), linear-gradient(#fff, #fff);
    background-size: cover, 100px 70%, cover; background-position: 0 0, 27px center;
    height: 51px; border-bottom: 5px solid #000;
  } */
  .topbar-nav1 .imp.logo {
    background-position: 27px center, 0 0; border-bottom: 5px solid #000 !important;
  }
  /* .topbar-nav1 .imp.logo:hover {
    background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0))
    , url(/public/logo.png), linear-gradient(#fff, #fff);
  } */
  .topbar-nav1 .imp.menu div.screen { opacity: 0.4; }
  .topbar-nav1 .imp.menu:hover div.screen { opacity: 0; }
}

/* .topbar-tut {
  width: 100%; height: 50px;
  background-color: rgb(247, 243, 239);
  position: sticky; top: 0; z-index: 9;
  display: flex; align-items: center;
  font-family: 'Ubuntu', sans-serif;
}
.topbar-left, .topbar-right {
  flex: 3;
  display: flex; align-items: center;
  justify-content: center;
}
.topbar-center {
  flex: 6;
}
.topbar-profile-pic {
  width: 42px; height: 42px; border-radius: 50%;
  object-fit: cover;
}
.topbar-social-icon {
  margin-right: 12px; cursor: pointer;
  font-size: 22px; color: var(--primary-color);
}
.topbar-list {
  display: flex; align-items: center; justify-content: center;
  list-style: none;
}
.topbar-list-item {
  margin-right: 22px; font-size: 20px; cursor: pointer;
  color: var(--primary-color);
}
.topbar-search {
  color: var(--primary-color); cursor: pointer; font-size: 20px;
  margin-left: 20px;
}

.dropdown { position: relative; display: inline-block; }
.dropdown-btn { text-decoration: none; }
.dropdown-content { position: absolute; z-index: 10; display: none; top:30px; left: -30px; }
.dropdown-content li { display: block; color: red; }
.dropdown-content li:hover { background-color: #ddd;}
.dropdown:hover .dropdown-content { display: block; }
.dropdown:hover .dropdown-btn { background-color: aqua; border-bottom: 3px solid #f00; } */
