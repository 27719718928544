.homepage .banner { position: relative; z-index: 1; overflow: hidden; }
.homepage .banner figure, .homepage .banner .overlay {
  height: 60vh; width: 100vw; /*padding: 0 5vw;*/
}
.homepage .banner figure>img {
  object-fit: cover; position: absolute; width: 100vw; /*height: 100%;*/
  top: 50%; transform: translateY(-50%); opacity: 0;
  transition: opacity 3s;
}
.homepage .banner.one figure>img.one, .homepage .banner.two figure>img.two,
.homepage .banner.three figure>img.three, .homepage .banner.four figure>img.four,
.homepage .banner.five figure>img.five, .homepage .banner.six figure>img.six { opacity: 1; }
/* .homepage .banner .overlay {
  position: absolute; top: 0;
  background-image: linear-gradient(to bottom right, rgb(0,0,0,0.3), transparent),
    linear-gradient(to bottom, rgb(255,255,255,0.5), transparent);
  background-size: 65% 100%, 36% 15%; background-repeat: no-repeat;
  background-position: 0 0, 64vw 0;
} */

/* .homepage .promo-container { background-color: rgb(230, 230, 230); padding: 5vh 0 0 2.6%; }
.homepage .promo { background-color: rgb(230, 230, 230); height: 50vh; overflow: hidden; }
.homepage .promo .left, .homepage .promo .right {
  display: inline-block; width: 50%; height: 300%; padding-right: 2.6%; transition: transform 1s;
}
.homepage .promo.one .left, .homepage .promo.one .right { transform: translate(0, 0%); }
.homepage .promo.two .left, .homepage .promo.two .right { transform: translate(0, -33%); }
.homepage .promo.three .left, .homepage .promo.three .right { transform: translate(0, -65.94%); }
.homepage .promo .left>div, .homepage .promo .right>div { height: 33%; padding: 1% 0; }
.homepage .promo img {
  width: 100%; height: 100%; /* box-shadow: inset 0 0 0 9px rgb(230, 230, 230); * /
}*/

.homepage section { padding-bottom: 4rem; padding-top: 4rem; }
.homepage section h2 {
  font-weight: 500; margin-bottom: 0.5rem; padding-bottom: 2rem;
  font-size: calc(1.36914rem + 1.42969vw); text-align: center;
}

/* ----------------------------------------------------------------------------------- */

.homepage section.biz {
  background-image: linear-gradient(#ffeff6,#fff); text-align: center;
}

.homepage section.biz .service-blocks { margin: 0 2.4vw; }
.homepage section.biz .service-block {
  display: inline-block; vertical-align: middle;
  width: 18.5vw; height: 64.38vh;
  background-color: #fff; margin: 0 2.4vw; padding-top: 5vh;
  box-shadow: 0 0 .375rem rgba(0,0,0,.1);
  /* border: 2px solid black; */
}
.homepage section.biz .service-block div {
  height: 24vh; padding: 4vh 0; margin: 0 1.4vw;
}
.homepage section.biz .service-block:first-of-type div {
  background-image: url(/public/ledger.jpg);
  background-size: cover; background-position: top;
}
.homepage section.biz .service-block:nth-of-type(2) div {
  background-image: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5))
  , url(/public/audit.jpg);
  background-size: cover; background-position: center;
}
.homepage section.biz .service-block:nth-of-type(3) div {
  background-image: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5))
  , url(/public/software.jpg);
  background-size: cover; background-position: center;
}
.homepage section.biz .service-block:nth-of-type(4) div {
  background-image: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5))
  , url(/public/support.jpg);
  background-size: cover; background-position: center;
}
.homepage section.biz .service-block i {
  font-size: 200%; padding: 15px;
  border-width: 3px; border-style: solid; border-radius: 50%;
}
.homepage section.biz .service-block:first-of-type i {
  margin-top: 7vh; margin-left: 11.3vw;
}
.homepage section.biz .service-block:nth-of-type(2) i {
  margin-top: 7vh; margin-left: 10.3vw;
}
.homepage section.biz .service-block:nth-of-type(3) i {
  margin-top: 7vh; margin-left: 7.5vw;
}
.homepage section.biz .service-block:nth-of-type(4) i {
  margin-top: 9.9vh; margin-left: -10.1vw;
}
.homepage section.biz .service-block h3 {
  font-weight: 400; font-size: calc(1.32031rem + .84375vw);
  padding-top: 2vw;
}
.homepage section.biz .service-block a { color: rgb(226, 19, 110); }

/* .homepage section.biz>img { width: 48%; } */

.homepage section.biz>h4 {
  padding-top: 4rem; padding-bottom: 4rem; color: #333; position: relative;
  font-size: 1.5625rem; font-weight: 500; text-transform: none;
}
.homepage section.biz>h4::before { margin-left: -50%; text-align: right; }
.homepage section.biz>h4::before, .homepage section.biz>h4::after {
  background-color: #dbdbdb; content: " "; height: 1px; overflow: hidden;
  position: absolute; top: 51%; width: 48%;
}
.homepage section.biz>h4::after { margin-left: 2%; }

.homepage section.biz .biz-services { padding: 5vh 0 5vh 2.7vw; }
.homepage section.biz .service { width: 16.3vw; float: left; padding: 0 3vw }
.homepage section.biz .service:first-of-type { width: 14vw; padding-right: 0; }
.homepage section.biz .service:nth-of-type(4) { width: 14vw; padding: 0; }
.homepage section.biz .service i { font-size: 300%; }
.homepage section.biz .service-block:nth-of-type(3n+1) i,
.homepage section.biz .service:nth-of-type(3n+1) i {
  color: rgba(237, 27, 36, 0.6); border-color: rgba(237, 27, 36, 0.6);
}
.homepage section.biz .service-block:nth-of-type(3n+2) i,
.homepage section.biz .service:nth-of-type(3n+2) i {
  color: rgba(27, 237, 41, 0.7); border-color: rgba(27, 237, 41, 0.7);
}
.homepage section.biz .service-block:nth-of-type(3n) i,
.homepage section.biz .service:nth-of-type(3n) i {
  color: rgba(226, 19, 110, 0.6); border-color: rgba(226, 19, 110, 0.6);
}
.homepage section.biz .service-block:nth-of-type(4n) i,
.homepage section.biz .service:nth-of-type(4n) i {
  color: rgba(78, 19, 226, 0.6); border-color: rgba(78, 19, 226, 0.6);
}
.homepage section.biz .service h5 { font-size: 140%; font-weight: 400; padding-top: 2vw; }
.homepage section.biz .biz-services::after { display: block; content: " "; clear: both; }

/* ----------------------------------------------------------------------------------- */

.homepage section.download {
  height: 93.5vh; background-image: url(/public/static-banner.webp);
  background-position: center; position: relative;
}
.homepage section.download img.mobile {
  height: 75vh; float: right; margin-right: 17vw; margin-top: 2vh;
}
.homepage section.download h2 {
  color: rgb(226, 19, 110); margin-top: 20vh; margin-bottom: 2vh;
}
.homepage section.download h2, .homepage section.download p {
  width: 50vw; margin-left: 15vw; text-align: left;
}
.homepage section.download p {
  color: rgb(255, 255, 255); font-size: 135%;
}
.homepage section.download img.social {
  display: inline-block; margin-top: 2vh; vertical-align: middle;
}
.homepage section.download img.google { margin-left: 14.3vw; width: 13vw; }
.homepage section.download img.apple { margin-top: 2.3vh; width: 11.5vw; }
.homepage section.download img.logo {
  position: absolute; width: 163px;
  top: 23.4vh; right: calc(17vw + 119.7px);
}
.homepage section.download .img-text {
  position: absolute; font-size: 94%; font-weight: 500;
  background-color: rgba(199, 192, 147, 0.329);
}
.homepage section.download .img-text.audit { top: 35.6vh; right: calc(17vw + 146px); }
.homepage section.download .img-text.software { top: 47.6vh; right: calc(17vw + 220px); }
.homepage section.download .img-text.support { top: 47.6vh; right: calc(17vw + 138px); }

.homepage em { font-style: italic; font-weight: bold; }
.homepage .divider-box { height: 10vh; }
.homepage .divider-box#about { background-color: rgb(230, 230, 230); }
.homepage .about-head, .homepage .about-foot { padding: 7vh 0 7vh 37.2vw; }
.homepage .about-head {
  background-image: url(/public/about.jpg);
  background-size: cover; background-position: center;
}
.homepage .about-head h1, .homepage .vision-head h1 {
  font-weight: 400; text-transform: uppercase;
}
.homepage .about-head h1 { font-size: 453.333%; }
.homepage .about {
  padding: 4vh 15vw 6vh 15.5vw;
  font-size: 220%; text-align: justify;
}
.homepage .about strong { color: #d00; }
.homepage .about-foot {
  transform: scaleX(-1); height: 24vh; margin-bottom: 20vh;
  background-color: rgba(0,0,0,0.6); background-repeat: no-repeat;
  background-image: url(/public/about.jpg), linear-gradient(rgba(255,0,0,0.25), #fff), linear-gradient(to right, #f00, rgba(0,0,0,0.95)), linear-gradient(#fff, #fff);
  background-size: 100% 12vh, 100% 9vh, 100% 9vh, 100% 10vh;
  background-position: 0 0, 0 15vh, 0 15vh, 0 14vh;
}

.homepage .vision-head { padding: 4vh 0 0 23vw; }
.homepage .vision-head {
  background-image: url(/public/csr.jpg);
  background-size: cover;
  background-position: center;
  color: #fff; height: 29.36vh;
}
.homepage .vision-head h1 {
  line-height: 1.1; background-color: #000; display: inline-block;
  padding: 0 1.5vw; font-size: 390%;
}
.homepage .vision-head h1:last-child { margin: 1vw 0 0 10vw; }
.homepage .vision {
  padding: 1vh 5vw 5vh 5vw; text-align: center;
  font-size: 160%; font-weight: 300; line-height: 1.25;
  letter-spacing: 1.5px;
}
.homepage .vision p { margin: 2vh 2vw 3vh; }
.homepage .vision h3 {
  font-weight: 600; margin-top: 4vh; position: relative;
}
.homepage .vision h3 i {
  font-size: 220%;
  display: inline-block; position: absolute; top: -2.3vh; left: 36%;
  width: 80px; text-align: center;
}
.homepage .vision .left h3 i { color: rgba(22, 131, 177, 0.7); }
.homepage .vision .right h3 i { color: rgba(7, 132, 7, 0.4); }
.homepage .vision .left, .homepage .vision .right {
  /* display: inline-block; vertical-align: middle; height: 50vh; */
  margin: 2vh 0; padding: 1vh 0; width: 100%;
  background-color: rgb(230, 230, 230);
}
.homepage .vision .left { margin-right: 2.6%; }
.homepage .vision .left p, .homepage .vision .right p { margin: 3vh 4vw; }
.homepage .vision .left em {
  font-weight: 600; font-style: italic; color:rgb(22, 131, 177);
}
.homepage .vision .right strong { font-weight: 500; }
.homepage .vision .right strong span {
  font-weight: 700; display: inline-block; margin-right: 0.2vw;
  background-color: #fff; width: 28px; text-align: center;
}
.homepage .vision .right ul {
  margin: 0 3vw 2vw 7vw; list-style: none;
  text-align: left; position: relative;
}
.homepage .vision .right ul i {
  font-size: 90%; color: rgb(7, 132, 7); line-height: 1.8;
  display: inline-block; position: absolute; left: -4.2vw; margin-top: -0.6vh;
  background-color: #fff; width: 42px; height: 42px; /* rgb(229, 115, 22) */
  border-radius: 50%; text-align: center; border: 2px solid rgb(7, 132, 7);
}
.homepage .vision .right ul li { margin-bottom: 3vh; }
.homepage .vision-foot {
  padding: 7vh 0 7vh 19vw; background-repeat: no-repeat;
  transform: scaleX(-1); height: 23vh; margin-bottom: 20vh;
  background-image: url(/public/csr-foot.jpg), linear-gradient(rgba(255,0,0,0.25), #fff)
    , linear-gradient(to right, #f00, rgba(0,0,0,0.95));
  background-size: 100% 12vh, 100% 9vh, 100% 8.975vh;
  background-position: 0 0, 0 13vh, 0 13vh;
}

.homepage .services-head {
  background-image: url(/public/services.jpg);
  background-size: cover; background-position: center;
  color: #fff; height: 29.36vh; padding: 8vh 0 0 41vw;
}
.homepage .services-head h1 {
  line-height: 1.1; display: inline-block;
  padding: 0 1.5vw; font-size: 450%; font-weight: 600;
  background-color: rgba(0,0,0,0.2);
}
.homepage .services-head h1 span {
  background-color: rgba(0,0,0,0.6);
}
.homepage .services {
  padding: 5vh 5vw 5vh 5vw; text-align: center;
  font-size: 200%; font-weight: 500; line-height: 1.25;
  letter-spacing: 1.5px; position: relative;
  background-color: rgba(0,0,0,0.1);
  /* background-size: cover; background-position: center; */
  /* background-image: url(/public/products.jpg); opacity: 0.5; */
}
.homepage .services img {
  position: absolute; top: 0; left: 0; object-position: center;
  object-fit: cover; opacity: 0.18; width: 100%; height: 100%;
}
.homepage .services ul {
  margin: 0 22vw; list-style: none; border-radius: 20px;
  text-align: left; padding: 2vh 4vw 0; color: rgb(255, 255, 255);
  background-color: rgba(50,5,5,0.85);
}
.homepage .services ul li { padding: 0 0 2vh 7vw; position: relative; }
.homepage .services ul i {
  font-size: 75%; color: rgb(22, 131, 177); line-height: 1.6;
  display: inline-block; position: absolute; left: 1vw; margin-top: -0.2vh;
  background-color: #fff; width: 5vw; height: 40px; /* rgb(229, 115, 22) */
  border-radius: 50%; text-align: center; border: 2px solid rgb(22, 131, 177);
}
.homepage .services ul .rocket { transform: rotate(-35deg); }
.homepage .services-foot {
  padding: 7vh 0 7vh 19vw; background-repeat: no-repeat;
  transform: scaleX(-1); height: 23vh; margin-bottom: 20vh;
  background-image: url(/public/services.jpg), linear-gradient(rgba(255,0,0,0.25), #fff)
    , linear-gradient(to right, #f00, rgba(0,0,0,0.95));
  background-size: 100% 15vh, 100% 9vh, 100% 8.975vh;
  background-position: 0 -2vh, 0 14vh, 0 14vh;
}
/* .homepage .vision-head h1 { position: absolute; } */
/* .homepage .about .left, .homepage .about .right {
  display: inline-block; width: 47.4%; height: 100%; margin-right: 2.6%;
  /* background-color: rgb(220, 220, 255); opacity: 0.5;* /
}
.homepage .about .left {
  /* background-color: blue; * /
} */
