.fp-sidebar {
  width: 23vw; height: 88vh; float: left;  /* width: 30vw, 27vw */
  background-color: rgba(0,100,200,0.3);
}
.fp-sidebar ul li {
  padding: 5px 1vw; font-size: 110%;
  border-bottom: 1px solid #cacaca;
}
.fp-sidebar ul li a {
  color: var(--primary-color); font-weight: 400;
  display: inline-block; padding: 2px 7px; /* width: 100%; */
}
.fp-sidebar ul li:hover { cursor: pointer; }
.fp-sidebar ul li:hover a { font-weight: 500; cursor: pointer; }
.fp-sidebar ul li a:focus {
  font-weight: 500; background-color: #f6f6f6;
  text-decoration: underline; box-shadow: 0 0 3px #666;
  outline: 1px solid rgba(102,102,102,0.5);
}

.fp-sidemenu {
  background-color: #fff; margin: 9.2vh 1vw;
  padding: 3.4vh 3vw; height: 76.5vh;
}
.fp-sidemenu h1 {
  color: var(--primary-color); font-size: 160%; font-weight: 500;
}
.fp-sidemenu ul { margin-top: 15px; }

.fp-login, .fp-logout, .fp-signup {
  position: absolute; top: 0px; padding: 3px 15px; border-radius: 5px; border-width: 1px;
}
.fp-login { left: 94%; }
.fp-logout { left: 100%; }
.fp-signup { left: 103%; }
